@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder {
  color: #b9b9b9 !important;
}

.select-line-height {
  line-height: 1.3;
}

@keyframes ldio-z7q7b8flzr {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio-z7q7b8flzr div {
  position: absolute;
  width: 18px;
  height: 18px;
  border: 3px solid #007bff;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-z7q7b8flzr div {
  animation: ldio-z7q7b8flzr 0.5988023952095808s linear infinite;
  top: 15px;
  left: 15px;
}

.loadingio-spinner-rolling-pel4utqdlna {
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.ldio-z7q7b8flzr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-z7q7b8flzr div {
  box-sizing: content-box;
}

label {
  cursor: auto !important;
}

label.custom-checkbox {
  cursor: pointer !important;
}

.custom-control-description {
  cursor: pointer !important;
}

.station-page :focus {
  border-color: black !important;
}

.station-page .btn-primary {
  background-color: black !important;
  border-color: black !important;
}